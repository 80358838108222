<template>
    <div>
        <section class="position-relative z-index-1 background-position-left-top background-no-repeat overflow-hidden"
            style="background-image: url('images/demo-elearning-02.png')">
            <div class="row justify-content-center mb-3">
                <div class="col-xl-8 col-lg-9 col-md-10 text-center">
                    <h3 class="text-dark-gray fw-700 ls-minus-1px">Our Services
                    </h3>
                    <span class="d-block fs-17 text-dark fw-500 mb-5px">We offer a wide range of services to meet your needs.</span>
                </div>
            </div>
            <div class="position-absolute right-0px bottom-minus-90px z-index-minus-1 d-none d-md-inline-block"
                data-bottom-top="transform: translateY(-50px)" data-top-bottom="transform: translateY(50px)">
                <img src="images/demo-elearning-04.png" alt="">
            </div>
            <div class="container">
                <div class="row g-4 row-cols-1 row-cols-lg-4 row-cols-sm-2 align-items-stretch mb-4">
                    <!-- Services cards with improved spacing and hover effects -->
                    <div class="col" v-for="(section, index) in displayedServices" :key="index">
                        <div class="service-card h-100 bg-white shadow-sm hover-shadow transition-all">
                            <a href="/services" class="service-link text-decoration-none">
                                <div class="p-4">
                                    <div class="service-icon-wrapper mb-4">
                                        <img :src="section.image" class="service-icon" :alt="section.title">
                                        <div class="icon-background"></div>
                                    </div>
                                    <h4 class="service-title mb-3">{{ section.title }}</h4>
                                    <p class="service-description mb-0">
                                        {{ trimText(section.content, 15) }}
                                    </p>
                                </div>
                                <div class="service-footer">
                                    <div class="btn-primary d-flex align-items-center justify-content-center">
                                        <span class="btn-text">More details</span>
                                        <i class="feather icon-feather-arrow-right ms-2"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <!-- View More button -->
                <div class="text-center mt-4 mb-5" v-if="sections.length > initialDisplayCount">
                    <button @click="loadMore" class="btn btn-outline-primary btn-lg">
                        {{ showingAll ? 'Show Less' : 'View More' }}
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

const sections = ref([]);
let main_title = ref('Loading...');
let titles = ref([]);
let serviceSections = ref([]);
let serviceImages = ref([]);
const trimText = (text, wordLimit) => {
    if (!text) return ''; // Return empty string if text is undefined
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

const initialDisplayCount = ref(4);
const showingAll = ref(false);

const displayedServices = computed(() => {
    const count = showingAll.value ? sections.value.length : initialDisplayCount.value;
    return sections.value.slice(0, count);
});

const loadMore = () => {
    showingAll.value = !showingAll.value;
};

const fetchData = async () => {
    const page = 'ServicesPage';
    const sectionIDs = [
        "665d5f639ac784c296cbd941",
        "665d5f7a9ac784c296cbd947",
        "665d5f979ac784c296cbd959",
        "665d5fba9ac784c296cbd973",
        "665d5fdc9ac784c296cbd995",
        "665d602b9ac784c296cbd9d0",
        "665d60499ac784c296cbda1e",
        "665d605c9ac784c296cbda5c",
        "665d607c9ac784c296cbdaa2",
        "665d60949ac784c296cbdaf0",
        "665d60b29ac784c296cbdb46",
        "665d60d59ac784c296cbdba4",
        "665d60fc9ac784c296cbdc0a",
        "665d61149ac784c296cbdc78",
        "665d61859ac784c296cbdcee"
    ];

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;
        const filteredSections = pageData.sections.filter(section => sectionIDs.includes(section._id));

        sections.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : 'content/surveyor/images/default_image.jpg'
        }));

        titles.value = filteredSections.map(section => section.title);
        serviceSections.value = filteredSections.map(section => section.content);
        serviceImages.value = filteredSections.map(section => section.images[0].url);

        main_title.value = filteredSections[0].title;

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};



onMounted(fetchData);
</script>

<style scoped>
.service-card {
    border-radius: 15px;
    transition: all 0.3s ease;
    border: 1px solid #eee;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-icon-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-icon {
    height: 60px;
    width: auto;
    position: relative;
    z-index: 2;
}

.icon-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f9fa;
    border-radius: 50%;
    z-index: 1;
}

.service-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ff0000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.service-description {
    color: #6c757d;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.6;
}

.service-footer {
    padding: 1rem;
    background-color: #ff0202;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: white;
    transition: background-color 0.3s ease;
}

.service-card:hover .service-footer {
    background-color: #f52f29;
}

.btn-outline-primary {
    border-width: 2px;
    border-color: #ff0000;
    padding: 0.75rem 2rem;
    font-weight: 600;
    color: #ff0000;
    transition: all 0.3s ease;
}

.btn-outline-primary:hover {
    transform: translateY(-2px);
}

/* Remove previous animation styles if not needed */
</style>