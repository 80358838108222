<template>
    <div>
        <section class="cover-background one-third-screen sm-h-500px pb-0 position-relative"
            :style="{ backgroundImage: `url(${firstSectionImage})` }">
            <div class="opacity-extra-medium bg-dark-gray"></div>
            <div class="container h-100">
                <div class="row align-items-center justify-content-center h-100">
                    <div
                        class="col-xl-8 col-lg-10 mb-9 md-mb-15 position-relative z-index-1 text-center d-flex flex-wrap align-items-center justify-content-center">

                        <h6 class="text-white fw-600 ls-1px mb-50px">{{ firstSectionContent }}
                        </h6>
                        <a href="/services"
                            class="btn btn-extra-large btn-switch-text btn-gradient-purple-pink btn-rounded me-10px">
                            <span>
                                <span class="btn-double-text" data-text="Explore">Explore</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="shape-image-animation p-0 w-100 bottom-minus-40px xl-bottom-0px d-none d-md-block">
                <svg xmlns="http://www.w3.org/2000/svg" widht="3000" height="400" viewBox="0 180 2500 200"
                    fill="#ffffff">
                    <path class="st1" d="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250">
                        <animate attributeName="d" dur="10s" values="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250;
                            M 0 250 C 400 50 400 400 3000 250 L 3000 550 L 0 550 L 0 250;
                            M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const firstSectionContent = ref('Loading...');
const firstSectionImage = ref('');

const fetchData = async () => {
    console.log('Component created, fetching data...');
    const page = 'HomePage';
    const sectionID = '6662d717d5a0f662e581d0f2';
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        console.log('Data fetched, processing response...');
        const section = response.data;
        console.log('Data fetched for first section:', section);
        if (section && section.images && section.images.length > 0) {
            firstSectionImage.value = section.images[0].url;
            console.log('Image URL:', firstSectionImage.value);
            firstSectionContent.value = section.content;
            console.log('Image and content set:', firstSectionContent.value, firstSectionImage.value);
        } else {
            firstSectionImage.value = '';
            firstSectionContent.value = 'No image available';
            console.log('No image available');
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        firstSectionContent.value = 'Error loading content';
    }
};
onMounted(fetchData);
</script>