<template>
    <div class="gallery-container">
        <div class="gallery-header">
            <h1>Our Gallery</h1>
            <div class="category-filters">
                <button 
                    v-for="category in categories" 
                    :key="category"
                    :class="{ active: selectedCategory === category }"
                    @click="filterByCategory(category)"
                >
                    {{ category }}
                </button>
            </div>
        </div>

        <div class="gallery-grid">
            <div 
                v-for="image in filteredImages" 
                :key="image.id" 
                class="gallery-item"
                @click="openLightbox(image)"
            >
                <img :src="image.thumbnail" :alt="image.title">
                <div class="image-overlay">
                    <h3>{{ image.title }}</h3>
                    <p>{{ image.description }}</p>
                </div>
            </div>
        </div>

        <!-- Lightbox -->
        <div v-if="selectedImage" class="lightbox" @click="closeLightbox">
            <button class="close-button" @click="closeLightbox">&times;</button>
            <button class="nav-button prev" @click.stop="navigateImage(-1)" v-if="currentIndex > 0">&lt;</button>
            <button class="nav-button next" @click.stop="navigateImage(1)" v-if="currentIndex < filteredImages.length - 1">&gt;</button>
            <div class="lightbox-content" @click.stop>
                <img :src="selectedImage.fullsize" :alt="selectedImage.title">
                <div class="image-info">
                    <h3>{{ selectedImage.title }}</h3>
                    <p>{{ selectedImage.description }}</p>
                    <span class="date">{{ selectedImage.date }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
 
export default {
    name: 'GalleryComponent',
    setup() {
        const images = ref([]);
        const selectedCategory = ref('All');
        const selectedImage = ref(null);
        const currentIndex = ref(0);
        const categories = ref(['All', 'Events', 'Office', 'Projects', 'Team']);
        const baseUrl = process.env.VUE_APP_API_BASE_URL;

        const fetchImages = async (category = 'All') => {
            try {
                const params = category !== 'All' ? { category } : {};
                const response = await axios.get(`${baseUrl}/gallery`, { params });
                images.value = response.data.data.map(img => ({
                    id: img._id,
                    title: img.title,
                    description: img.description,
                    category: img.category,
                    date: new Date(img.createdAt).toISOString().split('T')[0],
                    thumbnail: img.url,
                    fullsize: img.url
                }));
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        // Update filterByCategory to fetch from API
        const filterByCategory = async (category) => {
            selectedCategory.value = category;
            selectedImage.value = null;
            await fetchImages(category);
        };

        const filteredImages = computed(() => {
            if (selectedCategory.value === 'All') {
                return images.value;
            }
            return images.value.filter(img => img.category === selectedCategory.value);
        });

        const openLightbox = (image) => {
            selectedImage.value = image;
            currentIndex.value = filteredImages.value.findIndex(img => img.id === image.id);
            document.body.style.overflow = 'hidden';
        };

        const closeLightbox = () => {
            selectedImage.value = null;
            document.body.style.overflow = '';
        };

        const navigateImage = (direction) => {
            currentIndex.value += direction;
            selectedImage.value = filteredImages.value[currentIndex.value];
        };

        onMounted(() => {
            fetchImages();
        });

        return {
            images,
            categories,
            selectedCategory,
            selectedImage,
            currentIndex,
            filteredImages,
            filterByCategory,
            openLightbox,
            closeLightbox,
            navigateImage
        };
    }
};
</script>

<style scoped>
.gallery-container {
    padding: 120px 20px 40px;
    max-width: 1400px;
    margin: 0 auto;
}

.gallery-header {
    text-align: center;
    margin-bottom: 40px;
}

.gallery-header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.category-filters {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.category-filters button {
    padding: 8px 20px;
    border: none;
    border-radius: 20px;
    background: #f8d7da;
    color: #990000;
    cursor: pointer;
    transition: all 0.3s ease;
}

.category-filters button.active,
.category-filters button:hover {
    background: #990000;
    color: white;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    aspect-ratio: 1;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.gallery-item:hover .image-overlay {
    transform: translateY(0);
}

.image-overlay h3 {
    margin: 0 0 10px 0;
    font-size: 1.2rem;
}

.image-overlay p {
    margin: 0;
    font-size: 0.9rem;
    opacity: 0.8;
}

/* Lightbox styles */
.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.lightbox-content {
    max-width: 90%;
    max-height: 90vh;
    position: relative;
}

.lightbox-content img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
}

.image-info {
    color: white;
    padding: 20px;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
    z-index: 1001;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: white;
    padding: 20px;
    cursor: pointer;
    font-size: 24px;
}

.nav-button.prev {
    left: 20px;
}

.nav-button.next {
    right: 20px;
}

@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .nav-button {
        padding: 10px;
    }   
}
</style>