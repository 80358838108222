<template>

    <div>
        <section class="cover-background full-screen ipad-top-space-margin py-0 md-h-750px sm-h-650px"
            :style="{ backgroundImage: 'url(' + firstSectionImage + ')' }">
            <div class="opacity-very-light bg-black"></div>
            <div class="shape-image-animation bottom-0 p-0 w-100 d-none d-md-block">
                <svg xmlns="http://www.w3.org/2000/svg" widht="3000" height="400" viewBox="0 180 2500 200"
                    fill="#ffffff">
                    <path class="st1" d="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250">
                        <animate attributeName="d" dur="5s" values="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250;
                                M 0 250 C 400 50 400 400 3000 250 L 3000 550 L 0 550 L 0 250;
                                M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250"
                            repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
            <div class="container h-100">
                <div class="row align-items-center h-100">
                    <div class="col-xl-6 col-lg-8 col-md-10 position-relative z-index-1">

                        <h4 class="text-white fw-600 ls-minus-2px mb-25px" style="font-size: 2.5rem;">
                            <div>{{ firstSectionContent.replace(' Private Limited', '') }}</div>
                            <div>Private Limited</div>
                        </h4>
                        <div>

                        </div>
                        <a href="/contact"
                            class="btn btn-extra-large btn-switch-text btn-gradient-purple-pink btn-rounded me-10px ls-0px mt-15px">
                            <span>
                                <span class="btn-double-text" data-text="Contact Us">Contact Us</span>
                                <span><i class="fa-regular fa-envelope"></i></span>
                            </span>
                        </a>

                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const firstSectionContent = ref('Loading...');
const firstSectionImage = ref('');

const fetchData = async () => {
    const page = 'HomePage';
    const sectionID = '665849501b769ba8c36b958b';
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        if (section && section.images && section.images.length > 0) {
            firstSectionImage.value = section.images[0].url;
            console.log('Image URL:', firstSectionImage.value);
            firstSectionContent.value = section.content;
            console.log('Image and content set:', firstSectionContent.value, firstSectionImage.value);
        } else {
            firstSectionImage.value = '';
            firstSectionContent.value = 'No image available';
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        firstSectionContent.value = 'Error loading content';
    }
};
onMounted(fetchData);
</script>

<style scoped>
h4 {
    font-size: 2.5rem;
}
</style>
