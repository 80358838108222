<template>
    <div>
        <section class="pt-3 sm-pt-50px">
            <div class="container" v-if="!isLoading">
                <div class="row mb-10 align-items-center">
                    <div class="col-lg-5 position-relative md-mb-20">
                        <div class="images-wrapper">
                            <!-- First image -->
                            <div class="image-container primary-image" 
                                 :class="{ 'active': activeImage === 'first', 'inactive': activeImage === 'second' }"
                                 @click="toggleImage('first')">
                                <img :src="sections.first?.imageUrl || defaultImage" 
                                     alt="First section image" 
                                     class="border-radius-8px">
                            </div>
                            <!-- Second image -->
                            <div class="image-container secondary-image"
                                 :class="{ 'active': activeImage === 'second', 'inactive': activeImage === 'first' }"
                                 @click="toggleImage('second')">
                                <img :src="sections.second?.imageUrl || defaultImage" 
                                     alt="Second section image"
                                     class="border-radius-8px" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 offset-lg-1">
                        <p class="mb-40px sm-mb-25px">{{ sections.first?.content || 'No content available' }}</p>
                        <p class="mb-40px sm-mb-25px">{{ sections.second?.content || 'No content available' }}</p>
                    </div>
                </div>
            </div>
            <div v-else class="container text-center">
                <p>Loading content...</p>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const isLoading = ref(true);
const defaultImage = 'default_image_url'; // Replace with your default image

const sections = ref({
    first: null,
    second: null
});

const sectionIds = {
    first: '665849501b769ba8c36b959d',
    second: '665849501b769ba8c36b959f'
};

const activeImage = ref(null);

const toggleImage = (imageName) => {
    if (activeImage.value === imageName) {
        activeImage.value = null;
    } else {
        activeImage.value = imageName;
    }
};

const fetchSection = async (sectionId) => {
    try {
        const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/pages/HomePage/sections/${sectionId}`
        );
        const section = response.data;
        
        return {
            title: section.title,
            content: section.content,
            imageUrl: section.images?.[0]?.url || defaultImage
        };
    } catch (error) {
        console.error(`Error fetching section ${sectionId}:`, error);
        return {
            title: 'Error',
            content: 'Error loading content',
            imageUrl: defaultImage
        };
    }
};

const loadAllSections = async () => {
    isLoading.value = true;
    try {
        const [firstSection, secondSection] = await Promise.all([
            fetchSection(sectionIds.first),
            fetchSection(sectionIds.second)
        ]);

        sections.value = {
            first: firstSection,
            second: secondSection
        };
    } catch (error) {
        console.error('Error loading sections:', error);
    } finally {
        isLoading.value = false;
    }
};

onMounted(() => {
    loadAllSections();
});
</script>

<style scoped>
.images-wrapper {
    position: relative;
    width: 100%;
    height: 500px; /* Adjust based on your needs */
}

.image-container {
    position: absolute;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    width: 70%;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.primary-image {
    top: 0;
    left: 0;
    z-index: 1;
}

.secondary-image {
    bottom: -50px;
    right: -15px;
    width: 60%;
    z-index: 1;
}

.image-container.active {
    transform: scale(1.05);
    z-index: 2;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.image-container.inactive {
    transform: scale(0.95);
    opacity: 0.8;
}

.image-container:hover:not(.active) {
    transform: scale(1.02);
}

@media (max-width: 768px) {
    .images-wrapper {
        height: 400px;
    }

    .secondary-image {
        right: 15px;
        bottom: -30px;
    }
}

@media (max-width: 576px) {
    .images-wrapper {
        height: 300px;
    }
    
    .primary-image {
        width: 80%;
    }
    
    .secondary-image {
        width: 60%;
    }
}
</style>
