<template>
    <div>
        <!-- Desktop Header -->
        <nav class="main-nav" :class="{ 'scrolled': isScrolled }">
            <div class="nav-container">
                <!-- Logo -->
                <div class="logo">
                    <a href="/">
                        <img :src="logoUrl" alt="Logo" />
                    </a>
                </div>

                <!-- Desktop Navigation -->
                <div class="desktop-nav">
                    <a href="/" class="nav-link">Home</a>

                    <div class="nav-dropdown">
                        <a href="#" class="nav-link" @mouseenter="isServicesOpen = true"
                            @mouseleave="isServicesOpen = false">
                            Services
                            <span class="dropdown-arrow" :class="{ 'active': isServicesOpen }">▼</span>
                        </a>
                        <div class="dropdown-menu" :class="{ 'show': isServicesOpen }"
                            @mouseenter="isServicesOpen = true" @mouseleave="isServicesOpen = false">
                            <template v-if="titles.length">
                                <a v-for="(title, index) in displayedTitles" :key="index" href="/services"
                                    class="dropdown-item">
                                    {{ title }}
                                </a>
                                <a v-if="titles.length > 4" href="/services" class="dropdown-item more-link">
                                    ...More
                                </a>
                            </template>
                        </div>
                    </div>


                    <a href="/apps" class="nav-link">Apps</a>
                    <a href="/why-us" class="nav-link">Why Us</a>
                    <a href="/gallery" class="nav-link">Gallery</a>
                    <a href="/join-us" class="nav-link">Join Us</a>
                    <a href="/testimonials" class="nav-link">Testimonials</a>
                </div>

                <!-- Contact Button -->
                <div class="search-container">
                    <form @submit="handleSearch" class="search-form">
                        <div class="input-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" class="search-icon" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M11 4a7 7 0 1 0 0 14 7 7 0 0 0 0-14zM21 21l-4.35-4.35" />
                            </svg>
                            <input 
                                type="text" 
                                v-model="searchQuery"
                                placeholder="Search" 
                                class="search-input"
                            >
                        </div>
                        <button type="submit" class="search-button">Search</button>
                    </form>
                </div>


                <!-- Hamburger Menu -->
                <button class="menu-toggle" @click="toggleMenu" :class="{ 'active': isMenuOpen }">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
        </nav>

        <!-- Mobile Menu Sidebar -->
        <div class="mobile-menu" :class="{ 'active': isMenuOpen }">
            <nav class="menu-items">
                <!-- Add search container at the top of mobile menu -->
                <div class="search-container mobile">
                    <form @submit="handleSearch" class="search-form">
                        <div class="input-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" class="search-icon" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M11 4a7 7 0 1 0 0 14 7 7 0 0 0 0-14zM21 21l-4.35-4.35" />
                            </svg>
                            <input 
                                type="text" 
                                v-model="searchQuery"
                                placeholder="Search" 
                                class="search-input"
                            >
                        </div>
                        <button type="submit" class="search-button">Search</button>
                    </form>
                </div>

                <a href="/" class="menu-item" @click="closeMenu">Home</a>

                <!-- Services Dropdown -->
                <div class="dropdown">
                    <div class="menu-item" @click="toggleServices">
                        Services
                        <span class="dropdown-arrow" :class="{ 'active': isServicesOpen }">▼</span>
                    </div>
                    <div class="submenu" :class="{ 'active': isServicesOpen }">
                        <template v-if="titles.length">
                            <a v-for="(title, index) in titles" :key="index" href="/services" class="submenu-item"
                                @click="closeMenu">
                                {{ title }}
                            </a>
                        </template>
                    </div>
                </div>

                <a href="/apps" class="menu-item" @click="closeMenu">Apps</a>
                <a href="/why-us" class="menu-item" @click="closeMenu">Why Us</a>
                <a href="/gallery" class="menu-item" @click="closeMenu">Gallery</a>
                <a href="/join-us" class="menu-item" @click="closeMenu">Join Us</a>
                <a href="/testimonials" class="menu-item" @click="closeMenu">Testimonials</a>
                <a href="/contact" class="menu-item" @click="closeMenu">Contact</a>
            </nav>
        </div>

        <!-- Overlay -->
        <div class="menu-overlay" :class="{ 'active': isMenuOpen }" @click="closeMenu"></div>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
    name: 'HeaderComponent',
    setup() {
        const isMenuOpen = ref(false);
        const isServicesOpen = ref(false);
        const titles = ref([]);
        const isScrolled = ref(false);
        const searchQuery = ref('');
        const router = useRouter();
        const logoUrl = ref('');

        // Modified searchableRoutes to be reactive
        const searchableRoutes = computed(() => [
            {
                path: '/services',
                keywords: titles.value, // Use the fetched titles as keywords
                sections: titles.value.map(title => ({
                    title,
                    path: `/services#${title.toLowerCase().replace(/\s+/g, '-')}`,
                    keywords: title.toLowerCase().split(' ')
                }))
            },
            {
                path: '/apps',
                keywords: ['applications', 'software', 'mobile apps', 'web apps'],
                sections: [
                    { title: 'Mobile Apps', path: '/apps#mobile', keywords: ['mobile', 'android', 'ios'] },
                    { title: 'Web Apps', path: '/apps#web', keywords: ['web', 'browser', 'application'] },
                ]
            },
            {
                path: '/why-us',
                keywords: ['why choose us', 'benefits', 'advantages', 'features'],
            },
            {
                path: '/gallery',
                keywords: ['gallery', 'portfolio', 'projects', 'work'],
            },
            {
                path: '/join-us',
                keywords: ['careers', 'jobs', 'positions', 'employment', 'work with us'],
            },
            {
                path: '/testimonials',
                keywords: ['reviews', 'feedback', 'clients', 'testimonials'],
            },
            {
                path: '/contact',
                keywords: ['contact', 'reach us', 'support', 'help', 'inquiry'],
            }
        ]);

        const findMatchingRoute = (query) => {
            const searchTerms = query.toLowerCase().split(' ');
            let bestMatch = null;
            let highestScore = 0;

            searchableRoutes.value.forEach(route => {
                let score = 0;
                
                // Check main route keywords
                route.keywords.forEach(keyword => {
                    searchTerms.forEach(term => {
                        if (keyword.toLowerCase().includes(term)) {
                            score += 1;
                        }
                    });
                });

                // Check section keywords if they exist
                if (route.sections) {
                    route.sections.forEach(section => {
                        let sectionScore = 0;
                        section.keywords.forEach(keyword => {
                            searchTerms.forEach(term => {
                                if (keyword.toLowerCase().includes(term)) {
                                    sectionScore += 1;
                                }
                            });
                        });
                        
                        if (sectionScore > score) {
                            score = sectionScore;
                            route.path = section.path; // Use section path instead
                        }
                    });
                }

                if (score > highestScore) {
                    highestScore = score;
                    bestMatch = route;
                }
            });

            return highestScore > 0 ? bestMatch : null;
        };

        const fetchTitles = async () => {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/services/titles`);
                titles.value = response.data;
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        const displayedTitles = computed(() => {
            return titles.value.slice(0, 4);
        });

        const handleScroll = () => {
            isScrolled.value = window.scrollY > 50;
        };

        const toggleMenu = () => {
            isMenuOpen.value = !isMenuOpen.value;
            if (isMenuOpen.value) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
                isServicesOpen.value = false;
            }
        };

        const toggleServices = (event) => {
            event.stopPropagation();
            isServicesOpen.value = !isServicesOpen.value;
        };

        const closeMenu = () => {
            isMenuOpen.value = false;
            isServicesOpen.value = false;
            document.body.style.overflow = '';
        };

        const handleEscape = (e) => {
            if (e.key === 'Escape') closeMenu();
        };

        const handleSearch = async (event) => {
            event.preventDefault();
            const query = searchQuery.value.trim();
            
            if (!query) return;

            const matchingRoute = findMatchingRoute(query);

            if (matchingRoute) {
                try {
                    // Navigate to the matching route
                    await router.push(matchingRoute.path);
                    
                    // If the route has a hash, scroll to it after a short delay
                    if (matchingRoute.path.includes('#')) {
                        setTimeout(() => {
                            const element = document.getElementById(matchingRoute.path.split('#')[1]);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });
                            }
                        }, 100);
                    }

                    // Clear search and close menu
                    searchQuery.value = '';
                    closeMenu();
                } catch (error) {
                    console.error('Navigation error:', error);
                }
            } else {
                // No matches found - you could show a message to the user
                alert('No matching content found. Please try different keywords.');
            }
        };

        const fetchLogo = async () => {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/logo`);
                if (response.data.success) {
                    logoUrl.value = response.data.logoUrl;
                    console.log(logoUrl.value);
                }
            } catch (error) {
                console.error('Error fetching logo:', error);
            }
        };

        onMounted(() => {
            fetchTitles();
            fetchLogo();
            document.addEventListener('keydown', handleEscape);
            window.addEventListener('scroll', handleScroll);
            handleScroll();
        });

        onBeforeUnmount(() => {
            document.removeEventListener('keydown', handleEscape);
            window.removeEventListener('scroll', handleScroll);
        });

        return {
            isMenuOpen,
            isServicesOpen,
            isScrolled,
            titles,
            toggleMenu,
            displayedTitles,
            toggleServices,
            closeMenu,
            searchQuery,
            handleSearch,
            logoUrl,
            searchableRoutes,
        };
    }
};
</script>

<style scoped>
/* Main Navigation */
.main-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    z-index: 100;
    padding: 20px;
    transition: all 0.3s ease;
}

.main-nav.scrolled {
    background: white;
    padding: 10px 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
    gap: 20px;
}

/* Logo */
.logo img {
    height: 60px;
    /* Increased logo size for desktop */
    width: auto;
    transition: filter 0.3s ease;
}

.scrolled .logo img {
    filter: none;
}

/* Desktop Navigation */
.desktop-nav {
    display: none;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    /* Centers the navigation */
}

/* Contact Button */
.contact-button {
    display: none;
    text-decoration: none;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;

    /* Initial state (transparent header) */
    color: white;
    background: transparent;
}

/* Scrolled state */
.scrolled .contact-button {
    color: #333;
    /* Black text when scrolled */
    background: transparent;
}

/* Hover state when scrolled */
.scrolled .contact-button:hover {
    color: #ff0000;
    /* Red text only on hover */
}

.nav-link {
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 8px 12px;
    position: relative;
    transition: color 0.3s ease;
}

.scrolled .nav-link {
    color: #333;
}

.nav-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-arrow {
    font-size: 12px;
    margin-left: 4px;
    transition: transform 0.3s ease;
}

.dropdown-arrow.active {
    transform: rotate(180deg);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    min-width: 220px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 0;
    margin-top: 10px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.3s ease;
    display: block !important;
    /* Always keep it in DOM */
}

.dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.dropdown-menu[style*="display: block"] {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.dropdown-item {
    display: block;
    padding: 10px 20px;
    color: #333;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.3s ease;
}

.dropdown-item:hover {
    background: #f5f5f5;
    color: #ff0000;
}

/* Scrolled state dropdown */
.scrolled .dropdown-menu {
    background: white;
    backdrop-filter: none;
}

.more-link {
    color: #666;
    font-style: italic;
    border-top: 1px solid #eee;
    margin-top: 5px;
}

.more-link:hover {
    color: #ff0000;
    background: #f5f5f5;
}


/* Hamburger Menu Button */
.menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1001;
}

.menu-toggle span {
    display: block;
    width: 100%;
    height: 2px;
    background: white;
    transition: all 0.3s ease;
}

.scrolled .menu-toggle span {
    background: #333;
}

.menu-toggle.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.active span:nth-child(2) {
    opacity: 0;
}

.menu-toggle.active span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
}

/* Mobile Menu */
.mobile-menu {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100vh;
    background: white;
    z-index: 1000;
    transition: left 0.3s ease;
    overflow-y: auto;
    padding: 80px 0 20px;
}

.mobile-menu.active {
    left: 0;
}

/* Menu Items */
.menu-items {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.menu-item {
    color: #333;
    text-decoration: none;
    padding: 15px 0;
    font-size: 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/* Dropdown */
.dropdown {
    position: relative;
}

.dropdown-arrow {
    font-size: 12px;
    transition: transform 0.3s ease;
}

.dropdown-arrow.active {
    transform: rotate(180deg);
}

.submenu {
    display: none;
    padding-left: 20px;
}

.submenu.active {
    display: block;
}

.submenu-item {
    display: block;
    color: #333;
    text-decoration: none;
    padding: 10px 0;
    font-size: 14px;
}

/* Overlay */
.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}

.menu-overlay.active {
    opacity: 1;
    visibility: visible;
}

/* Container styling */
.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

/* Form wrapper */
.search-form {
    display: flex;
    align-items: center;
    background-color: #fff; /* White background */
    border-radius: 20px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 2px;
    width: 200px;
    border: 1px solid #f8d7da; /* Light red border */
}

/* Input wrapper */
.input-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 8px;
}

/* Input field styling */
.search-input {
    border: none;
    outline: none;
    flex: 1;
    padding: 6px 0;
    font-size: 14px;
    background-color: transparent;
    color: #990000; /* Subtle dark red for text */
    font-family: Arial, sans-serif;
}

/* Search icon */
.search-icon {
    width: 16px;
    height: 16px;
    stroke: #990000; /* Subtle dark red for the icon */
    margin-right: 6px;
}

/* Button styling */
.search-button {
    background-color: #f8d7da; /* Light red background */
    color: #990000; /* Dark red text */
    border: none;
    padding: 2px 8px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: Arial, sans-serif;
}

.search-button:hover {
    background-color: #f5c2c7; /* Slightly darker light red on hover */
}

/* Desktop Styles */
@media (min-width: 992px) {
    .desktop-nav {
        display: flex;
        flex: 1;
        justify-content: center;
    }

    .contact-button {
        display: block;
    }

    .menu-toggle,
    .mobile-menu,
    .menu-overlay {
        display: none;
    }

    .logo img {
        height: 80px;
        /* Even larger logo for desktop */
    }
}

/* Hover effects for desktop */
@media (min-width: 992px) {
    .nav-link:hover {
        opacity: 0.8;
    }

    .dropdown-item:hover {
        background: #f5f5f5;
    }
}

@media (min-width: 992px) {
    .contact-button {
        display: inline-block;
    }
}

/* Add these new styles */
.search-container.mobile {
    padding: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.search-container.mobile .search-form {
    width: 100%;
}

/* Update media query to hide desktop search */
@media (max-width: 991px) {
    .nav-container .search-container {
        display: none;
    }
}

.search-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.suggestion-item {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.suggestion-item:hover {
    background-color: #f5f5f5;
}
</style>